<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <!-- <Login/> -->
  <!-- <Container/> -->

  <!-- <keep-alive>
        <router-view></router-view>
  </keep-alive> -->
  <el-config-provider :locale="locale">
    <router-view  v-slot="{ Component }">
        <keep-alive>
          <component :is="Component"></component>
        </keep-alive>
    </router-view>
</el-config-provider>

</template>

<script>

import { ElConfigProvider } from 'element-plus';
import zhCn   from 'element-plus/es/locale/lang/zh-cn';

export default {
  name: 'App',
  components: {
    // Login,
    //Container,
    ElConfigProvider,
  },
  setup(){
    const debounce = (callback, delay) => {
      let tid;
      return function (...args) {
        const ctx = self;
        tid && clearTimeout(tid);
        tid = setTimeout(() => {
          callback.apply(ctx, args);
        }, delay);
      };
    };

    const _ = (window).ResizeObserver;
    (window).ResizeObserver = class ResizeObserver extends _ {
      constructor(callback) {
        callback = debounce(callback, 20);
        super(callback);
      }
    };
  
    return {
      locale:zhCn,
    }
  }
}
</script>

<style>
@import url("assets/svg/svg.css");
*{
  padding: 0px;
  margin: 0px;
  user-select: none;
}

body {
  overflow:auto;
}
.clear-side
{
  margin: 0px;
  padding: 0px;
}

.clear-overflow
{
  overflow:hidden;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #ffffff;
  /* margin-top: 60px; */
}
img
{
  background-size:contain;
  vertical-align:top;
  font-size:0;
  
}

.span{

  color: rgb(49, 49, 49);
  font-size: 14px;
}

a{

color: rgb(237, 163, 110);
font-size: 14px;
}

.a-color{
background: rgb(217, 146, 95);
color: rgb(217, 146, 95);
}


a:hover{

  color: rgb(255, 246, 149);
  
}


::-webkit-scrollbar {
    width: 6px; /* Firefox/IE */
    height: 6px; /* Firefox/IE */
    background-color: #F5F5F5; /* 背景颜色 */
}
 
/* 设置垂直滚动条的粗细为3px */
::-webkit-scrollbar-thumb {
    border-radius: 2px; /* 滑块外形半径 */
    background-color: #e3e3e3; /* 滑块颜色 */
}
 
/* 设置垂直滚动条的粗细为4px */
::-webkit-scrollbar-track {
    border-radius: 2px; /* 滑道外形半径 */
    background-color: #F5F5F5; /* 滑道颜色 */
}


input
{
   line-height: 24px;
   width:200px;
}


.btn{
    width: 100px;
    height: 30px;
    background-image:linear-gradient(to right top,rgb(40, 128, 200),rgb(57, 126, 167),rgb(46, 181, 222));
    color:rgb(255, 255, 255);
    text-align: center;
    font-size: 16px;
    border: 1px solid rgb(60, 89, 116);
    box-shadow: 1px 1px 1px 0px #585858; 
    margin-left: 20px;
    margin-right:  20px;
    border-radius:5px;
}

.btn:hover
{
  box-shadow: 0px 0px 5px 0px #f6f5f5; 
}
.btn:active
{
  background-image:linear-gradient(to right top,(57, 126, 167) rgb(163, 213, 255),rgb(46, 181, 222),rgb(167, 215, 253));
}

.box-color
{
  background-image:linear-gradient(to right top,rgb(57, 126, 167),rgb(57, 126, 167),rgb(57, 126, 167));
}
.btn-color
{
  background-image:linear-gradient(to right top,rgb(40, 128, 200),rgb(57, 126, 167),rgb(46, 181, 222));
  /* color:rgb(255, 255, 255); */
  border: 1px solid rgb(60, 89, 116);
  color: #ffffff;
  /* font-size: 16px;
  text-align: center; */
  --el-button-hover-text-color: #fff;
  --el-button-hover-bg-color: var(--el-color-primary-light-9);
  --el-button-hover-border-color: var(--el-color-primary-light-7);
  --el-button-active-text-color: #e4d095;
  --el-button-active-border-color: #fff;
  --el-button-active-bg-color: var(--el-button-hover-bg-color);
}

.box
{
  width: 350px;
  padding-bottom: 10px;
  box-shadow: 1px 1px 10px 0px #080808; 
  background-color: #287d99;
  border-radius:5px;
}
.flex{
  display: flex;

}
.flex-align-items-center
{
  display: flex;
  align-items:center;
}

.flex-justify-content-center
{
  display: flex;
  justify-content:center;
}

.flex-justify-content-between
{
  display: flex;
  justify-content:space-between;
}

.flex-justify-end
{
  display: flex;
  justify-content:flex-end;
}

.flex-justify-content-wrap
{
  display: flex;
  flex-wrap:wrap;
}

.flex-column {
  display: flex;
  flex-direction:column;
}

.flex-grow {
  flex-grow: 1;
}

.inline-center
{
  text-align: center;
}

.base-button
{
  padding: 7px 10px 7px 10px;
  min-width: 80px;
}
.base-button-small
{
  padding: 0px 6px 0px 6px;
  min-width: 50px;
  min-height: 0px;
  height: 30px;
  border-radius: 1px;
  font-size: 14px;

}


</style>

import MYTOOL from '@/utils/myTool'
import {createRouter,createWebHistory} from "vue-router";
import storeInfoFun from "../info"
import { ElMessage } from "element-plus";



// route.beforeEach((from,to,next)=>{
//    // console.log(from)
//    // console.log(to)
//     next();
// })
// route.afterEach((from,to)=>{
//    // console.log(from)
//   //  console.log(to)
// })

var homeFuns = []
homeFuns.push(
    {
        name: "home_100",
        path: "100", 
        components:{
          homelayouts:()=>import('@/home/newstage/index.vue'),
        }
    })

for(let i = 1;i < 100;i++)
{
    homeFuns.push(
        {
            name: "home_" + i,
            path: "" + i, 
  
        })
}

var routes = [{
    name: "index",//可以简化命名,包括显示在游览器url上的，在to必须这样写:to="{'name':'abt'}"
    path: "/",
   // component: Login,
    component: () =>import(/* webpackChunkName: "about" */ "@/login/index.vue"),
    
    //linkActiveClass: 'active'
    meta:{
     
    }
},
{
    name: "login",//可以简化命名,包括显示在游览器url上的，在to必须这样写:to="{'name':'abt'}"
    path: "/login",
   // component: Login,
    component: () =>import(/* webpackChunkName: "about" */ "@/login/index.vue"),
    meta:{
        keepAlive: false
    }
    
    //linkActiveClass: 'active'
},
{
    name: "home",
    path: "/home", 
    component: () =>import(/* webpackChunkName: "about" */ "@/home/index.vue"),
    meta:{
  
    },
    children:homeFuns
},

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  });


  router.beforeEach((to, from, next) => {
    //console.log("to",to)
    //console.log("from",from)
    let storeInfo = storeInfoFun();
    const token = storeInfo.loadToken();
    if (to.path !== '/login' && to.path !== '/') {
        if (MYTOOL.isEmpty(token)) {
            ElMessage({type:"error",message:"登录验证错误!"});
            storeInfo.logoutFlash = true
            next({path: '/login'})
            
        } else {
            next()
        }

    } else if(to.path == '/login' || to.path == '/')
    {
       // from.meta.keepAlive = false
       //console.log("?????????from",from)
       
        next()
    }
    else {
        next()
    }
})


export default router;